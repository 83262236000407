import React, { useState } from "react";
import "../assets/css/Home.css";
import { graphql } from "gatsby";
import { Container, Row, Col } from "react-bootstrap";
import Img from "gatsby-image";
import Layout from "../components/Layout";
import SEO from "../components/SEO";
import ContactForm from "../components/ContactForm";
import '@fontsource-variable/dancing-script';

export default ({ data }) => {
  const [show, setShow] = useState(false);
  const handleShow = () => {
    setShow(true);
  };
  const handleClose = () => {
    setShow(false);
  };
  return (
    <>
      <Layout transparent>
        <SEO
        title="Home"
        description="YCGAMING | We Buy and Sell Retro Games, Consoles and Accessories in Soham, Ely, Newmarket and areas in Cmabridgeshire, Suffolk and Norfolk"
        ></SEO>
        <div className="home-background">
          <div className="container">
            <Container className="p-1">
              <Row>
                <Col sm={12} md={12} lg={12} className="p-1">
                  <div className="sicily-norwich-pizzeria">
                      <h1 className="italian-heading pt-4">We Buy and Sell Retro Games, Consoles and Accessories</h1>
                      <p>Looking for someone to buy your old computer game consoles or trading cards?</p>
                      <p className="">All games, consoles and controllers considered, cash paid on collection of items and we will beat CEX prices.</p>
                    </div>
                </Col>
                <Col sm={12} md={6} lg={6} className="p-1">
                  <div className="sicily-norwich-pizzeria">
                      <h2 className="pt-4 italian-heading">Contact us form</h2>
                      <p className="pt-2">
                        Drop us a message if you have anything you would like to discuss with us.
                      </p>
                      <ContactForm/>
                    </div>
                </Col>
                <Col sm={12} md={6} lg={6} className="p-2">
                  <div className="sicily-norwich-pizzeria">
                  <h2 className="pt-2 italian-heading">Don't let it be game over</h2>
                    <Img fluid={data.sicilypasta.childImageSharp.fluid} />
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      </Layout>
    </>
  );
};
export const query = graphql`
  query  {
    sicilypasta: file(relativePath: { eq: "arcade.webp" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid(maxWidth: 800, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
